.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 90vh;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: #8e8e96;
  border: 5vh solid #f5d5d3;
  font-family: "Courier New", Courier, monospace;
}

.App-copyright {
  font-size: calc(-2px + 2vmin);
  color: #8e8e96;
}

h1 {
  font-family: "Montserrat", sans-serif, monospace;
  font-weight: 900;
  font-size: calc(40px + 2vmin);
  color: black;
}

h2 {
  font-family: "Montserrat", sans-serif, monospace;
  font-weight: 900;
  font-size: calc(15px + 2vmin);
  color: black;
}

a {
  color: #8e8e96;
}
